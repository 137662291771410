import React from 'react';
import SEO from '../components/seo';
import { useIntl } from 'gatsby-plugin-react-intl';
import { graphql } from 'gatsby';
import { parseLocale } from '../locale';
import { ProgramListQuery } from '../../graphql-types';
import { mapProgramListQueryToProgramListProps } from '../data-mappers/program-list.mapper';
import { ProgramListTemplate } from '../templates/program-list.template';
import { TranslationData } from './newsroom';

export const query = graphql`
  query ProgramList($language: String, $paginatedCollectionName: String) {
    cms {
      programList(locale: $language) {
        ...ProgramListContent
      }
      categories(locale: $language, sort: "created_at:desc") {
        ...ProgramCategory
        programCount
      }
    }
    paginatedCollectionPage(collection: { name: { eq: $paginatedCollectionName } }) {
      ...PaginatedCollectionPage
    }
  }

  fragment ProgramListContent on cms_ProgramList {
    showContentTags
    header {
      text
      title
      media {
        ...CommonMediaField
        staticFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
      youtubeEmbedId
    }
    metas {
      description
    }
  }

  fragment ProgramCategoryWithProgramId on cms_Category {
    ...ProgramCategory
    programCount
    programs {
      id
    }
  }

  fragment ProgramCategory on cms_Category {
    id
    title
    description
    slug
    localizations {
      ...CategoryLocalization
    }
  }

  fragment CategoryLocalization on cms_Category {
    id
    slug
    locale
  }
`;

const ProgramListPage: React.FC<{ data: ProgramListQuery }> = ({ data }) => {
  const intl = useIntl();
  const locale = parseLocale(intl.locale);

  if (!data?.cms?.programList) return null;

  const translation: TranslationData = {
    locale: locale,
    load_more: intl.formatMessage({ id: 'load_more' }),
    read_more: intl.formatMessage({ id: 'read_more' }),
  };
  const programListProps = mapProgramListQueryToProgramListProps(data, translation);

  return (
    <>
      <SEO
        lang={locale}
        title={intl.formatMessage({ id: 'programs' })}
        description={data.cms.programList.metas?.description ?? ''}
      />

      {programListProps && <ProgramListTemplate {...programListProps} />}
    </>
  );
};

export default ProgramListPage;
